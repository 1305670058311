<ng-container [formGroup]="formGroup">
    <p-treeSelect
        id="parents"
        [formControlName]="formField"
        [options]="treeNodes"
        [metaKeySelection]="false"
        [filter]="true"
        [filterInputAutoFocus]="true"
        [propagateSelectionUp]="false"
        [showClear]="true"
        [placeholder]="selectLabel"
        selectionMode="checkbox"
        class="table-filter"
        appendTo="body">
        <ng-template pTemplate="value" let-items="$implicit">
            {{ selectLabel }}
            <span class="badge text-bg-primary" *ngIf="items && items.length">{{
                items.length
            }}</span>
        </ng-template>
    </p-treeSelect>
</ng-container>

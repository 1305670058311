/* eslint-disable @typescript-eslint/no-explicit-any */
import { DBConfig } from 'ngx-indexed-db';
import { PendingUpload } from '../services/data-storage.service';
import { RecordingService } from '../services/recording.service';

export const IpCamerasStore = 'ipCameras';
export const PendingUploadsStore = 'pendingUploads';
export const SelectedMediaDevicesStore = 'selectedMediaDevices';

export const DbConfig: DBConfig = {
    name: 'FI Insight',
    //Increment this version whenever schema changes to automatically apply migrations on initialization
    version: 5,
    objectStoresMeta: [
        {
            store: IpCamerasStore,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
        },
        {
            store: PendingUploadsStore,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [],
        },
        {
            store: SelectedMediaDevicesStore,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [],
        },
    ],
    migrationFactory: () => {
        return {
            4: async (db, transaction): Promise<void> => {
                const data: PendingUpload[] = [];
                await new Promise<void>((resolve) => {
                    transaction
                        .objectStore(PendingUploadsStore)
                        .getAll().onsuccess = (ev: any): void => {
                        data.push(...(ev.target?.result ?? []));
                        resolve();
                    };
                });
                db.deleteObjectStore(PendingUploadsStore);
                db.createObjectStore(PendingUploadsStore, {
                    keyPath: 'id',
                    autoIncrement: false,
                });
                for (const datum of data) {
                    datum.id = (datum as any).uploadKey;
                    (datum as any).dealTransactionId =
                        (datum as any).dealTransactionId ??
                        (datum as any).dealInfo?.dealTransactionId;
                    datum.customerName =
                        datum.customerName ??
                        (datum as any).dealInfo?.customerName;
                    datum.pendingDataChunks = datum.pendingDataChunks ?? [
                        (datum as any).pendingData,
                    ];
                    transaction.objectStore(PendingUploadsStore).add(datum);
                }
            },
            5: async (db, transaction): Promise<void> => {
                const data: PendingUpload[] = [];
                await new Promise<void>((resolve) => {
                    transaction
                        .objectStore(PendingUploadsStore)
                        .getAll().onsuccess = (ev: any): void => {
                        data.push(...(ev.target?.result ?? []));
                        resolve();
                    };
                });
                transaction.objectStore(PendingUploadsStore).clear();
                for (const datum of data) {
                    datum.id = (datum as any).dealTransactionId;
                    datum.dealRecordingId = (datum as any).dealTransactionId;
                    datum.uploadStarted = false;
                    datum.videoObjectKey = (datum as any).uploadKey;
                    datum.thumbnailObjectKey = datum.videoObjectKey.replace(
                        /\.[^.]+$/,
                        RecordingService.THUMBNAIL_EXTENSION,
                    );
                    transaction.objectStore(PendingUploadsStore).add(datum);
                }
            },
        };
    },
};

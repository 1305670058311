import { Pipe, PipeTransform } from '@angular/core';
import { Int32NameValuePair } from '../service-proxies/service-proxies';
import { PascalCaseToSpaces } from '../utils/string-utils';

@Pipe({ name: 'enumToNameValuePairs' })
export class EnumToNameValuePairsPipe implements PipeTransform {
    transform(value: object): Int32NameValuePair[] {
        return Object.keys(value)
            .filter((key) => isNaN(parseInt(key)))
            .map((key) =>
                Int32NameValuePair.fromJS({
                    name: PascalCaseToSpaces(key),
                    value: value[key as keyof object],
                }),
            );
    }
}

import { Injectable } from '@angular/core';
import { RubyApiProxyService } from 'src/app/core/services/ruby-api-proxy.service';
import {
    AddLogEntryCommand,
    DealRecordingsServiceProxy,
    IAddLogEntryCommand,
    LogEntryType,
} from '../../shared/service-proxies/service-proxies';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    constructor(
        private dealRecordingsServiceProxy: DealRecordingsServiceProxy,
        private featureFlagsService: FeatureFlagsService,
        private rubyApiProxyService: RubyApiProxyService,
    ) {}

    log(
        type: LogEntryType,
        message: string,
        dealRecordingId: number | undefined = undefined,
        payload: unknown | undefined = undefined,
    ): void {
        console.log(message);
        if (!dealRecordingId) {
            return;
        }

        if (this.featureFlagsService.useDotNetBackEnd) {
            void firstValueFrom(
                this.dealRecordingsServiceProxy.addLogEntry(
                    AddLogEntryCommand.fromJS({
                        dealRecordingId,
                        type,
                        message,
                        payload: payload ? JSON.stringify(payload) : null,
                    } as IAddLogEntryCommand),
                ),
            );
        } else {
            void this.rubyApiProxyService.addLogEntry({
                deal_transaction_id: dealRecordingId,
                description: message,
            });
        }
    }
}

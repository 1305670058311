/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorModalService } from '../services/error-modal.service';
import { RubyApiProxyService } from '../services/ruby-api-proxy.service';
import { WindowsServiceBaseUrl } from 'src/app/modules/recording/config/windows-worker-config';

@Injectable({
    providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private errorModalService: ErrorModalService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                //all auth requests have custom error handling
                if (
                    !req.url.includes('/api/auth') &&
                    !req.url.includes(RubyApiProxyService.verifyUserUrl) &&
                    !req.url.includes(RubyApiProxyService.addLogEntryUrl) &&
                    !req.url.includes(WindowsServiceBaseUrl)
                ) {
                    this.errorModalService.showForHttpErrorResponse(error);
                }
                return throwError(() => error);
            }),
        );
    }
}

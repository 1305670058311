export class MediaDevice {
    id: string;
    label: string;
    info: MediaDeviceInfo | IpCameraInfo;

    get isAudioDevice(): boolean {
        return (
            this.isIpCamera ||
            (this.info as MediaDeviceInfo).kind === 'audioinput'
        );
    }

    get isVideoDevice(): boolean {
        return (
            this.isIpCamera ||
            (this.info as MediaDeviceInfo).kind === 'videoinput'
        );
    }

    get isIpCamera(): boolean {
        return 'url' in this.info;
    }

    constructor(
        id: string,
        label: string,
        info: MediaDeviceInfo | IpCameraInfo,
    ) {
        this.id = id;
        this.label = label;
        this.info = info;
    }

    static fromIpCamera(info: IpCameraInfo): MediaDevice {
        return new MediaDevice(info.id, info.label, info);
    }

    static fromUsbDevice(info: MediaDeviceInfo): MediaDevice {
        return new MediaDevice(info.deviceId, info.label, info);
    }
}

export interface IpCameraInfo {
    id: string;
    label: string;
    url: string;
    username?: string;
    password?: string;
}

export interface SelectedMediaDevices {
    audioDevice: MediaDevice | null;
    videoDevice: MediaDevice | null;
}

export interface SelectedMediaDeviceIds {
    audioDeviceId: string | null;
    videoDeviceId: string | null;
}

export function PascalCaseToSpaces(value: string | null): string {
    if (!value) {
        return '';
    }

    return value.replace(/([A-Z])/g, ' $1').trim();
}

export function SnakeCaseToPascalCase(value: string | null): string {
    if (!value) {
        return '';
    }

    return value
        .toLowerCase()
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
}

export function PascalCaseToSnakeCase(value: string | null): string {
    if (!value) {
        return '';
    }

    return value
        .replace(/([A-Z])/g, '_$1')
        .toLowerCase()
        .replace(/^_/, '');
}

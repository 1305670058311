export class MediaSource {
    private source: MediaStream | string;

    get isMediaStream(): boolean {
        return typeof this.source !== 'string';
    }

    get isIpCameraStream(): boolean {
        return typeof this.source === 'string';
    }

    get mediaStream(): MediaStream {
        if (!this.isMediaStream) {
            throw Error('Media source does not support a stream!');
        }

        return this.source as MediaStream;
    }

    get ipCameraStream(): string {
        if (!this.isIpCameraStream) {
            throw Error('Media source does not support IP camera stream!');
        }

        return this.source as string;
    }

    constructor(source: MediaStream | string) {
        this.source = source;
    }
}

import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import { RubyApiProxyService } from 'src/app/core/services/ruby-api-proxy.service';
import {
    AuthServiceProxy,
    AwsCredentials,
    IAwsCredentials,
} from '../../shared/service-proxies/service-proxies';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AwsAuthService {
    private credentials: AwsCredentials | undefined;

    get credentialsExpired(): boolean {
        return (
            !this.credentials?.expiration ||
            this.credentials.expiration <= DateTime.local()
        );
    }

    constructor(
        private authServiceProxy: AuthServiceProxy,
        private featureFlagsService: FeatureFlagsService,
        private rubyApiProxyService: RubyApiProxyService,
    ) {}

    async getCredentials(): Promise<AwsCredentials> {
        if (!this.credentials || this.credentialsExpired) {
            if (this.featureFlagsService.useDotNetBackEnd) {
                this.credentials = await firstValueFrom(
                    this.authServiceProxy.awsCredentials(),
                );
            } else {
                const res = await this.rubyApiProxyService.getAwsCredentials();
                this.credentials = AwsCredentials.fromJS({
                    identityId: res.identity_id,
                    token: res.token,
                    expiration: DateTime.fromISO(res.expiration),
                } as IAwsCredentials);
            }
        }

        return this.credentials;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'flattenStringList' })
export class FlattenStringListPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(items: string[], displayCount: number = 1): SafeHtml {
        if (!items || !items.length) return '';

        const totalCount = items.length;

        if (totalCount === 0) {
            return '';
        }

        let result = items
            .filter((item, index) => index < displayCount)
            .map((item) => item)
            .join(', ');

        if (totalCount > displayCount) {
            const extraItems = items
                .filter((item, index) => index >= displayCount)
                .map((item) => item)
                .join(', ');
            result +=
                ", <u title='" +
                extraItems +
                "'>+" +
                (totalCount - displayCount) +
                '</u>';
        }
        return this.sanitizer.bypassSecurityTrustHtml(result);
    }
}

import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { DataStorageService, PendingUpload } from './data-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import {
    AbortMultipartUploadCommand,
    CompleteMultipartUploadCommand,
    CreateMultipartUploadCommand,
    GetObjectCommand,
    PutObjectCommand,
    S3Client,
    UploadPartCommand,
} from '@aws-sdk/client-s3';
import { fromCognitoIdentity } from '@aws-sdk/credential-providers';
import { environment } from 'src/environments/environment';
import { AwsAuthService } from './aws-auth.service';
import { Buffer } from 'buffer';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { Duration } from 'luxon';
import { LogEntryType } from '../../shared/service-proxies/service-proxies';

@Injectable({
    providedIn: 'root',
})
export class AwsS3Service {
    public static INSTALLER_EXPIRY_SECONDS = Duration.fromObject({
        hours: 12,
    }).as('seconds');

    private s3Client: S3Client | undefined;

    constructor(
        private authService: AuthService,
        private awsAuthService: AwsAuthService,
        private dataStorageService: DataStorageService,
        private loggingService: LoggingService,
    ) {}

    getObjectKey(dealRecordingId: number, extension: string): string {
        if (!this.authService.user) {
            throw Error('User is not authenticated!');
        }
        return `user-${this.authService.user.id}/fi-insight-${dealRecordingId}.${extension}`;
    }

    async uploadData(
        pendingUpload: PendingUpload,
        isLastPart = false,
    ): Promise<void> {
        const pendingUploadLength = pendingUpload.pendingDataChunks.length;
        const data = new Blob(
            pendingUpload.pendingDataChunks.slice(0, pendingUploadLength),
        );

        if (!pendingUpload.uploadId && isLastPart && data.size === 0) {
            console.warn(
                'Skipping upload; recording stopped with no data to upload!',
            );
            return;
        }

        if (!pendingUpload.uploadId) {
            pendingUpload.uploadId =
                await this.createMultipartUpload(pendingUpload);
            await this.dataStorageService.storePendingUpload(pendingUpload);
        }

        if (data.size >= 5500000 || (isLastPart && data.size > 0)) {
            await this.uploadPart(pendingUpload, data);
            pendingUpload.pendingDataChunks.splice(0, pendingUploadLength);
            await this.dataStorageService.storePendingUpload(pendingUpload);
        }

        if (isLastPart) {
            await this.completeMultipartUpload(pendingUpload);
            pendingUpload.videoUploadCompleted = true;
            await this.dataStorageService.storePendingUpload(pendingUpload);
        }
    }

    async uploadThumbnailImage(pendingUpload: PendingUpload): Promise<void> {
        if (!pendingUpload.videoThumbnail) {
            return;
        }

        const buffer = Buffer.from(
            pendingUpload.videoThumbnail.replace(
                /^data:image\/\w+;base64,/,
                '',
            ),
            'base64',
        );
        const s3Client = await this.getS3Client();
        await s3Client.send(
            new PutObjectCommand({
                Body: buffer,
                Bucket: environment.aws.s3Bucket,
                Key: pendingUpload.thumbnailObjectKey,
                ContentType: 'image/png',
            }),
        );
        this.loggingService.log(
            LogEntryType.ThumbnailImageUploadCompleted,
            'Thumbnail image uploaded successfully.',
            pendingUpload.dealRecordingId,
            { ObjectKey: pendingUpload.thumbnailObjectKey },
        );
    }

    async abortUpload(pendingUpload: PendingUpload): Promise<void> {
        const s3Client = await this.getS3Client();
        await s3Client.send(
            new AbortMultipartUploadCommand({
                Bucket: environment.aws.s3Bucket,
                Key: pendingUpload.videoObjectKey,
                UploadId: pendingUpload.uploadId,
            }),
        );
    }

    async getMsiFileName(): Promise<string | null> {
        const s3Client = await this.getS3Client();

        const command = new GetObjectCommand({
            Bucket: environment.aws.s3InstallersBucket,
            Key: `${environment.aws.s3InstallerObjectKeyPrefix}/installer.txt`,
        });
        const response = await s3Client.send(command);
        if (response.Body === undefined) {
            return null;
        }

        return (await response.Body.transformToString()).trimEnd();
    }

    async getPresignedUrl(
        fileName: string,
        expiry = AwsS3Service.INSTALLER_EXPIRY_SECONDS,
    ): Promise<string> {
        const s3Client = await this.getS3Client();
        const command = new GetObjectCommand({
            Bucket: environment.aws.s3InstallersBucket,
            Key: `${environment.aws.s3InstallerObjectKeyPrefix}/${fileName}`,
        });
        return await getSignedUrl(s3Client, command, { expiresIn: expiry }); // URL expires in 12 hours
    }

    private async createMultipartUpload(
        pendingUpload: PendingUpload,
    ): Promise<string | undefined> {
        const s3Client = await this.getS3Client();
        const result = await s3Client.send(
            new CreateMultipartUploadCommand({
                Bucket: environment.aws.s3Bucket,
                Key: pendingUpload.videoObjectKey,
            }),
        );
        return result.UploadId;
    }

    private async uploadPart(
        pendingUpload: PendingUpload,
        data: Blob,
    ): Promise<void> {
        const partNumber = this.getCurrentPartNumber(pendingUpload);
        const megabytes = data.size / 1000000;
        this.loggingService.log(
            LogEntryType.PartUploadStarted,
            `Starting part ${partNumber} upload (${megabytes} MB).`,
            pendingUpload.dealRecordingId,
            {
                ObjectKey: pendingUpload.videoObjectKey,
                UploadId: pendingUpload.uploadId,
                PartNumber: partNumber,
                Size: megabytes,
            },
        );

        const s3Client = await this.getS3Client();
        const result = await s3Client.send(
            new UploadPartCommand({
                Body: data,
                Bucket: environment.aws.s3Bucket,
                Key: pendingUpload.videoObjectKey,
                PartNumber: partNumber,
                UploadId: pendingUpload.uploadId,
            }),
        );

        pendingUpload.completedParts.push({
            ETag: result.ETag,
            PartNumber: partNumber,
        });

        this.loggingService.log(
            LogEntryType.PartUploadCompleted,
            `Part ${partNumber} uploaded successfully.`,
            pendingUpload.dealRecordingId,
            {
                ObjectKey: pendingUpload.videoObjectKey,
                UploadId: pendingUpload.uploadId,
                PartNumber: partNumber,
                ETag: result.ETag,
            },
        );
    }

    private async completeMultipartUpload(
        pendingUpload: PendingUpload,
    ): Promise<void> {
        const s3Client = await this.getS3Client();
        try {
            await s3Client.send(
                new CompleteMultipartUploadCommand({
                    Bucket: environment.aws.s3Bucket,
                    Key: pendingUpload.videoObjectKey,
                    MultipartUpload: {
                        Parts: pendingUpload.completedParts,
                    },
                    UploadId: pendingUpload.uploadId,
                }),
            );
        } catch (err) {
            if (err instanceof Error && err.name === 'NoSuchUpload') {
                return;
            }

            throw err;
        }
    }

    private getCurrentPartNumber(pendingUpload: PendingUpload): number {
        const completedPartNumbers = pendingUpload.completedParts.map(
            (p) => p.PartNumber ?? 0,
        );
        if (!completedPartNumbers.length) {
            return 1;
        }
        return Math.max(...completedPartNumbers) + 1;
    }

    private async getS3Client(): Promise<S3Client> {
        return this.s3Client && !this.awsAuthService.credentialsExpired
            ? this.s3Client
            : await this.refreshS3Client();
    }

    private async refreshS3Client(): Promise<S3Client> {
        const oldClient = this.s3Client;

        const credentials = await this.awsAuthService.getCredentials();
        this.s3Client = new S3Client({
            region: environment.aws.region,
            credentials: fromCognitoIdentity({
                clientConfig: { region: environment.aws.region },
                identityId: credentials.identityId ?? '',
                logins: {
                    'cognito-identity.amazonaws.com': credentials.token ?? '',
                },
            }),
        });

        oldClient?.destroy();
        return this.s3Client;
    }
}

<ng-container [formGroup]="formGroup">
    <p-multiSelect
        [options]="selectOptions"
        optionLabel="name"
        optionValue="value"
        class="table-filter"
        [placeholder]="selectLabel"
        [formControlName]="formField"
        [showClear]="true"
        appendTo="body">
        <ng-template pTemplate="selectedItems" let-items="$implicit">
            {{ selectLabel }}
            <span class="badge text-bg-primary" *ngIf="items && items.length">{{
                items.length
            }}</span>
        </ng-template>
    </p-multiSelect>
</ng-container>

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-filter-tree-select',
    templateUrl: './filter-tree-select.component.html',
})
export class FilterTreeSelectComponent {
    @Input() treeNodes: TreeNode[] | undefined;
    @Input() selectLabel: string = '';
    @Input() formGroup!: FormGroup;
    @Input() formField!: string;
}
